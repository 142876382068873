

































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { debounce } from 'underscore'
import { QrcodeStream } from 'vue-qrcode-reader'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'
import BAutocomplete from '@/components/validation/BAutocomplete.vue'
import DialogDelete from '@/components/DialogDelete.vue'
import Toolbar from '@/components/CRUD/Toolbar.vue'
import _ from 'underscore'
import ListQR from './CRUD/ListQR.vue'
import { ExportToCsv, ExportToPdf } from '@/plugins/export-file'

@Component({
  components: {
    QrcodeStream,
    'AppForm': ValidationObserver,
    'AppInput': BInput,
    'AppAuto': BAutocomplete,
    'AppDialogDelete': DialogDelete,
    'AppToolbar': Toolbar,
    'CRUDListQR': ListQR
  }
})
export default class RekapSuratJalan extends Vue {
  //#region COMPUTED
  get name() {
    return this.editedItem ? this.editedItem.invoice : ''
  }
  get loading() {
    return this.$store.getters.loading
  }
  get error() {
    return this.$store.getters.error
  }
  get imageURL() {
    return process.env.VUE_APP_API
  }
  get getSignature() {
    return this.selected ? `${this.imageURL}/${this.selected.urlSignature}` : ``
  }

  get dataItems() {
    return this.$store.getters['scan/rekapSuratJalan/data']
  }
  get items() {
    return this.dataItems.data
  }
  get totalItems() {
    return this.dataItems.totalRecords
  }
  get options() {
    return this.$store.getters['scan/rekapSuratJalan/options']
  }
  set options(newVal) {
    this.$store.dispatch('scan/rekapSuratJalan/OnOptions', newVal)
  }
  get search() {
    return this.$store.getters['scan/rekapSuratJalan/search']
  }
  set search(newVal) {
    this.$store.dispatch('scan/rekapSuratJalan/OnSearch', newVal)
    this.debouncedGetAnswer()
  }

  get formTitle() {
    return this.editedItem.id ? 'Update Item' : 'New Item'
  }
  //#endregion
  //#region DATA
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
    signaturePad: any
  }
  debouncedGetAnswer:any = null
  headers:Array<object> = [
    { text: 'ID', align: 'start', value: 'id' },
    { text: 'No.Rekap', value: 'invoice' },
    { text: 'Tanggal', value: 'pickupDate' },
    { text: 'Kurir', value: 'courierName' },
    { text: 'No.Plat', value: 'vehicleNumber' },
    // { text: 'Act', align: 'center', value: 'actions', sortable: false },
  ]
  footers:object = { itemsPerPageOptions: [ 25, 50, 100 ] }
  dialog:boolean = false
  dialogDelete:boolean = false
  tab:any = null
  snackbar:any = { dialog: false, error: false, message: '' }
  tabItems:any = [ 'Kurir', 'Scan', 'TTD' ]
  camera:any = 'auto'
  editedItem:any = {
    courierName: null,
    pickupDate: null,
    vehicleNumber: null,
    signature: null,
    listBarcode: [],
  }
  expanded:any = []
  selected:any = null
  deletedItem:any = { dialog: false, data: {} }
  //#endregion
  //#region WATCH
  @Watch('dialog')
  handler(val:boolean) {
    val || this.close()
  }
  @Watch('dialogDelete')
  dialogDeleteChanged(val:boolean) {
    val || this.close()
  }
  //#endregion
  //#region METHODS
  onSearch(value:string) {
    this.search = value
  }
  initialize() {
    if (this.options.page !== 1) this.options.page = 1
    else this.$store.dispatch('scan/rekapSuratJalan/ReadData')
  }
  editItem(item:any, act:string) {
   
    if (act === 'edit') {
    } else if (act === 'copy') {
    } else if (act === 'delete') {
      this.editedItem = item
      this.dialogDelete = true
    }
  }
  confirmDeleteItem(val:boolean) {
    if (val) this.$store.dispatch('scan/rekapSuratJalan/DeleteData', this.editedItem)
    this.dialogDelete = false
  }
  deleteItemDetail(item:any) {
    this.deletedItem.dialog = true
    this.deletedItem.data = item
  }
  confirmDeleteItemDetail(val:boolean) {
    if (val) this.$store.dispatch('scan/rekapSuratJalan/DeleteDataDetail', this.deletedItem.data)
    this.deletedItem.dialog = false
    this.deletedItem.data = {}
  }
  saveItem() {
    this.$refs.observer.validate()
      .then(async success => {
        if (!success) return
        
        console.log(this.editedItem);
        
        if (this.editedItem.id) {
          this.$store.dispatch('scan/rekapSuratJalan/UpdateData', this.editedItem)
        } else {
          this.$store.dispatch('scan/rekapSuratJalan/CreateData', this.editedItem)
        }
        this.close()
      })
  }
  close() {
    this.dialog = false
    this.dialogDelete = false
    this.tab = null
    if (this.editedItem.signature) this.clear()
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, {
        courierName: null,
        pickupDate: null,
        vehicleNumber: null,
        signature: null,
        listBarcode: [],
      })
      const resetForm = this.$refs.observer
      if (resetForm) resetForm.reset()
    })
  }
  async onDecode(val:any) {
    this.camera = 'off'
    const existVal = this.editedItem.listBarcode.find((x:any) => x.barcode === val)
    if (existVal) {
      this.snackbar.error = true
      this.snackbar.dialog = true
      this.snackbar.text = `QR sudah ada di list`
    } else {
      this.snackbar.error = false
      this.snackbar.dialog = true
      this.snackbar.text = `Scanned ${val}`
      this.editedItem.listBarcode.push({ barcode: val })
    }
    setTimeout(() => this.camera = 'auto', 200)
  }
  reset() {
    this.camera = 'auto'
  }

  undo() {
    this.$refs.signaturePad.undoSignature()
    this.save()
  }
  clear() {
    this.$refs.signaturePad.clearSignature()
    this.save()
  }
  onBegin() {
    console.log('=== Begin ===')
  }
  onEnd() {
    console.log('=== End ===')
    this.save()
  }
  save() {
    const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

    if (!isEmpty) {
      fetch(data)
        .then(response => response.blob())
        .then(response => {
          this.editedItem.signature = response
        })
    } else {
      this.editedItem.signature = null
    }
  }

  onPDF(item:any) {
    let stylesHtml = ''
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) stylesHtml += node.outerHTML

    let prtHtml = ``;
    prtHtml += `<table style='font-family: Tahoma; width: 8.5in; border-collapse: collapse; text-transform: uppercase; color: black;'>`
    prtHtml += `<thead>`
    prtHtml += `<tr>`
    prtHtml += `<th colspan='4' style='text-align: left; padding-left: 250px;'>REKAP SURAT JALAN</th>`
    prtHtml += `</tr>`
    prtHtml += `<tr>`
    prtHtml += `<td style='width: 10%'>No.Rekap</td>`
    prtHtml += `<td style='width: 40%'>: ${item.invoice}</td>`
    prtHtml += `<td style='width: 10%'>Kurir</td>`
    prtHtml += `<td style='width: 40%'>: ${item.courierName}</td>`
    prtHtml += `</tr>`
    prtHtml += `<tr>`
    prtHtml += `<td style='width: 10%'>Tanggal</td>`
    prtHtml += `<td style='width: 40%'>: ${this.$options.filters!.datetime1(item.pickupDate)}</td>`
    prtHtml += `<td style='width: 10%'>No.Plat</td>`
    prtHtml += `<td style='width: 40%'>: ${item.vehicleNumber}</td>`
    prtHtml += `</tr>`
    prtHtml += `<tr>`
    prtHtml += `<th colspan='4' style='text-align:left; font-size: 12px; padding-top: 10px;'>List Barcode</th>`
    prtHtml += `</tr>`
    prtHtml += `</thead>`

    prtHtml += `<tbody style='font-size: 12px;'>`
    item.listTrackingSuratJalan.forEach((detail:any) => {
      prtHtml += `<tr>`
      prtHtml += `<td>${detail.barcode}</td>`
      prtHtml += `</tr>`
    });
    prtHtml += `</tbody>`

    prtHtml += `<tfoot>`
    prtHtml += `<tr>`
    prtHtml += `<td colspan='4' style='text-align: center; padding-left: 250px;'>`
    prtHtml += `Terima Kasih <br>`
    prtHtml += `<img src='${this.imageURL}/${item.urlSignature}' width='100' height='100' alt='signature'> <br>`
    prtHtml += `${item.courierName}<br>______________`
    prtHtml += `</td>`
    prtHtml += `</tr>`
    prtHtml += `</tfoot>`
    prtHtml += `</table>`

    const html = `<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`

    const title = item.invoice
    const opt = {
      margin: 0.2,
      filename: `${title}_${new Date().getTime()}`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    }
    ExportToPdf(opt, html)
  }
  print() {
    // Get HTML to print from element
    // @ts-ignore
    const prtHtml = document.getElementById('printMe').innerHTML
    // Get all stylesheets HTML
    let stylesHtml = ''
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) stylesHtml += node.outerHTML
    // Open the print window
    const WinPrint:any = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

    const html = `<!DOCTYPE html><html><head>${stylesHtml}</head><body>${prtHtml}</body></html>`
    WinPrint.document.write(html)
    
    window.setTimeout(() => {
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }, 500)
  }
  //#endregion
  //#region CREATED
  //#endregion
  //#region MOUNTED
  mounted() {
    this.debouncedGetAnswer = debounce(this.initialize, 500)
  }
  //#endregion
}
