
























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import BInput from '@/components/validation/BInput.vue'

@Component({
  components: {
    'AppForm': ValidationObserver,
    'AppInput': BInput,
  }
})
export default class ListQR extends Vue {
  @Prop() items:any
  //#region COMPUTED
  get formTitle() {
    return this.indexQR === -1 ? 'New QR' : 'Edit QR'
  }
  //#endregion
  //#region DATA
  $refs!: { observer1: InstanceType<typeof ValidationObserver> }
  dialog:boolean = false
  indexQR:number = -1
  defaultQR:any = { barcode: '' }
  editedQR:any = { barcode: '' }
  //#endregion
  //#region WATCH
  @Watch('dialog')
  handler(val:boolean) {
    val || this.closeQR()
  }
  //#endregion
  //#region METHODS
  closeQR() {
    this.dialog = false
    this.$nextTick(() => {
      this.indexQR = -1
      this.editedQR = Object.assign({}, this.defaultQR)
      const resetForm = this.$refs.observer1
      if (resetForm) resetForm.reset()
    })
  }
  editQR(item:any) {
    this.indexQR = this.items.indexOf(item)
    this.editedQR = item
    this.dialog = true
  }
  saveQR() {
    this.$refs.observer1.validate().then(async success => {
      if (!success) return

      const existVal = this.items.find((x:any) => x.barcode === this.editedQR.barcode)
      if (existVal) return
      
      if (this.indexQR > -1) Object.assign(this.items[this.indexQR], this.editedQR)
      else this.items.push(this.editedQR)
      this.closeQR()
    })
  }
  deleteQR(item:any) {
    const idx = this.items.indexOf(item)
    this.items.splice(idx, 1)
  }
  //#endregion
  //#region CREATED
  //#endregion
  //#region MOUNTED
  //#endregion
}
