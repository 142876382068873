export const ExportToCsv = (title:string, value:any) => {
  if (!value.length) {
    return
  }
  let csvContent = "data:text/csv;charset=utf-8,"
  csvContent += [
    Object.keys(value[0]).join(";"),
    ...value.map((item:any) => Object.values(item).join(";"))
  ]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "")
  const data = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', `${new Date().getTime()}_${title}.csv`)
  link.click()
}

export const ExportToPdf = (opt:any, value:any) => {
  const html2PDF = require('html2pdf.js')
  html2PDF()
    .set(opt)
    .from(value)
    .save()
}
